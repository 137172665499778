import React, { useCallback, useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import Button from './Button';
import { color, breakpoints, sizing, font } from '../../utils/style';

const Bg = styled('div')`
  .compress {
    overflow: hidden;
    margin-bottom: 22px;
    height: 290px;
    p {
      font-size: ${sizing.text};
      color: ${color.textWhite};
      line-height: 1.57;
    }
    h1 {
      font-size: ${sizing.heading};
      margin-bottom: 20px;
      @media (max-width: ${breakpoints.xs}) {
        font-size: 22px;
        margin-bottom: 18px;
      }
    }

    @media (max-width: ${breakpoints.sm}) {
      height: 336px;
    }
  }

  .button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 133px;
    height: 42px;
    border-radius: 8px;
    border: solid 1px ${color.lightGrey};
    font-family: ${font.default};
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #e9e9e9;
    line-height: 1.2rem;
    outline: none;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }

    a {
      padding: 10px 20px;
    }
    span {
      font-size: 16px;
      color: ${color.lightGrey};
    }
    &.rotate {
      svg {
        transform: rotate(180deg);
      }
      p {
        font-size: ${sizing.text};
      }
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    margin-bottom: 60px;
    .compress,
    .rotate {
      p {
        font-size: ${sizing.default};
      }
    }
  }
`;

const SeoContent = () => {
  const [isCompress, setIsCompress] = useState('compress');
  const readMore = useCallback(() => {
    setIsCompress(!isCompress);
  }, [isCompress]);

  return (
    <StaticQuery
      query={graphql`
        {
          allWordpressPage(filter: { slug: { eq: "home" } }) {
            edges {
              node {
                acf {
                  seo_content
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const content = data.allWordpressPage.edges[0].node.acf.seo_content;
        return (
          <Bg>
            <div className={isCompress ? 'compress' : 'rotate'} dangerouslySetInnerHTML={{ __html: content }} />
            <Button
              text={isCompress ? 'อ่านเพิ่มเติม' : 'อ่านน้อยลง'}
              ariaText="อ่านเพิ่มเติม"
              className={isCompress ? 'button withBorder' : 'button withBorder rotate'}
              iconName="arrow-down-simple"
              iconColor={color.lightGrey}
              iconSize="15"
              customHandler={readMore}
            />
          </Bg>
        );
      }}
    />
  );
};

export default SeoContent;
