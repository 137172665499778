import React from 'react';
import styled from '@emotion/styled';
import { breakpoints } from '../../utils/style';
import SeoContent from './SeoContent';
import Video from './Video';

const WrapperGameInfo = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  &.gray {
    margin: 60px 0;
    & > div {
      & .compress {
        height: 290px;
      }
      .button {
        opacity: 1;
      }
    }
  }

  & .home-video-popup {
    & > div {
      max-width: 100%;
      width: 50%;
      top: 15%;
      left: 25%;

      & > div {
        &:first-of-type {
          text-align: left;
          color: #fff;
        }
      }
    }
    & iframe {
      width: 100%;
      height: 385px;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    flex-flow: row wrap;

    &.gray {
      & div {
        & .compress {
          height: 312px;
        }
      }
    }

    & .home-video-popup {
      & > div {
        width: calc(100% - 32px);
        top: 30%;
        left: 16px;
      }

      & iframe {
        width: 100%;
        height: 154px;
      }
    }
  }

  @media (max-width: ${breakpoints.md}) and (orientation: landscape) {
    & .home-video-popup {
      & > div {
        top: 5%;

        & > div {
          padding: 0 0 0 0;

          & > button {
            top: 5px;
            right: 0;
          }
        }
      }

      & iframe {
        height: 190px;
      }
    }
  }
`;

const SBOSBA = ({ mode = '' }) => (
  <WrapperGameInfo className={mode}>
    <SeoContent />
    <Video
      iframeOptions={{
        allowfullscreen: 'allowfullscreen',
        mozallowfullscreen: 'mozallowfullscreen',
        msallowfullscreen: 'msallowfullscreen',
        oallowfullscreen: 'oallowfullscreen',
        webkitallowfullscreen: 'webkitallowfullscreen',
      }}
    />
  </WrapperGameInfo>
);

export default SBOSBA;
