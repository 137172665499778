import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import Slider from 'react-slick';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import {
  breakpoints, border, title, resetList, flex, sizing, color, font,
} from '../../utils/style';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import liveСasino from '../../img/live-casino.svg';
import slider_arrow_mobile from '../../img/IconsSvg/slider-arrow_mobile.svg';
import { categoryLinksInterpolate, getFile, validateLink } from '../../utils/system';
import SBOSBA from '../../components/shared/SBOSBA';
import WhySbobet from '../../components/shared/WhySbobet';
import dictionary from '../../utils/dictionary';
import SlickSlideMenuContentMobile from '../../components/shared/SlickSlideMenuContentMobile';
import PlayItem from '../../components/Content/CasinoGamesPage/PlayItem';
import CookieDisableComponent from '../../components/shared/CookieDisableError';

const Wrapper = styled('div')`
  p {
    margin-bottom: 1rem;
  }
  .page_description {
    margin: 60px 0 30px;
    & h1 {
      color: ${color.blue};
      font-size: ${sizing.heading};
      font-weight: ${font.headingWeight};
      margin-bottom: 20px;
      margin-top: 0;
    }
    & .description_content {
      color: ${color.textWhite};
      font-size: ${sizing.text};
      line-height: 1.71;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    .page_description {
      margin: 20px 0;
      h1 {
        margin-bottom: 16px;
      }
    }
  }
`;

const FeaturedWrapper = styled('div')`
  position: relative;
  margin: 15px 0 0 0;
  width: 100%;
  border-radius: 4px;
  ${title};
  .breadcrumbs {
    position: absolute;
    z-index: 1;
    top: 20px;
    left: 30px;
  }
  .gatsby-image-wrapper {
    border-radius: ${border.borderRadius};
    img {
      width: 100%;
    }
    @media (max-width: ${breakpoints.md}) {
      border-radius: ${border.borderRadius} !important;
    }
  }
  & > h1 {
    left: 115px;
    & > img {
      width: 89px;
      height: 89px;
      top: 0;
      left: -90px;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    & > h1 {
      & > img {
        width: 50px;
        height: 50px;
        left: -50px;
      }
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    overflow: hidden;
    .breadcrumbs {
      top: 16px;
      left: 16px;
      margin: 0;
    }
    & h1 {
      left: 13px;
      position: absolute;
      bottom: 0;
      top: 40%;
      padding: 0;
      margin: 0;
      display: flex;

      & img {
        left: 0;
        top: 0;
        width: 30px;
        height: 30px;
      }
      span {
        font-size: 22px;
        margin-left: 35px;
      }
    }
    .gatsby-image-wrapper {
      height: 130px;
      border-radius: ${border.borderRadius} ${border.borderRadius} 0 0;
      width: 145%;
      left: -90px;

      & img {
        left: -20px;
      }
    }
  }
`;

const MenuWrapper = styled('div')`
  width: 100%;
  margin-top: 30px;
`;

const MenuTabs = styled('ul')`
  ${resetList};
  ${flex};
  justify-content: space-between;
  & li {
    width: 100%;
    height: 48px;
    border-radius: 8px 8px 0 0;
    cursor: pointer;
    overflow: hidden;
    margin-right: 8px;
    &:last-of-type {
      margin-right: 0;
    }
    & > a {
      width: 100%;
      height: 100%;
      background-color: #111731;
      transition: 0.3s;
      text-transform: uppercase;
      ${flex};
      font-weight: ${font.headingWeight};
      font-size: 16px;
      line-height: 24px;
      &.active,
      &:hover {
        background-color: ${color.darkPurple};
        color: ${color.white};
      }
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    justify-content: flex-start;
    &::-webkit-scrollbar {
      background-color: transparent;
      display: none;
    }
    &::-webkit-scrollbar-track {
      box-shadow: none !important;
      background: transparent;
      width: 0;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(255, 0, 0, 0.1) !important; // manage scrollbar thumb background color here
    }
    li {
      height: auto;
      margin-right: 1px;
      overflow: initial;
      white-space: nowrap;
      a {
        font-size: ${sizing.text};
        line-height: 16px;
        padding: 15px 5px;
      }
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    li {
      margin-right: 4px;
      margin-bottom: 4px;
    }
  }
`;

const MenuContent = styled('div')`
  width: 100%;
  height: max-content;
  background-color: ${color.darkPurple};
  border-radius: 0 0 8px 8px;
  padding: 32px 34px 0 34px;
  ${flex};
  justify-content: space-between;
  flex-wrap: wrap;
  & > a {
    width: 247px;
    height: max-content;
    margin-bottom: 30px;
    & .text {
      width: 100%;
      margin-top: 8px;
      font-family: ${font.default};
      font-style: normal;
      font-weight: ${font.normalWeight};
      font-size: ${sizing.text};
      line-height: 15px;
      text-align: center;
      text-transform: uppercase;
      color: ${color.white};
    }
  }
  &.mobile-view {
    display: none;
  }
  @media (max-width: ${breakpoints.s}) {
    & a {
      width: 100%;
    }
    &.desktop-view {
      display: none;
    }
    &.mobile-view {
      display: block;
      padding-top: 70px;
      padding-bottom: 40px;
      padding-left: 16px;
      padding-right: 16px;
      margin-top: -5px;
      & .slick-slider {
        position: relative;
        & .slick-list {
          margin-bottom: -20px;
          overflow: hidden;
          padding-top: 10px;
        }
        & .slick-slide {
          padding-bottom: 16px;
          .text {
            margin-top: 9px;
          }
        }
      }
      & .slick-arrow {
        position: absolute;
        top: -50px;
        right: 0;
        font-size: 0;
        width: 36px;
        height: 36px;
        background-color: #575c74;
        border-radius: 8px;
        border: none;
        transform: rotate(90deg);
        outline: none;
        &.slick-prev {
          right: 50px;
          transform: rotate(-90deg);
        }
        &:after {
          content: '';
          background-image: url(${slider_arrow_mobile});
          position: absolute;
          display: block;
          color: ${color.white};
          font-size: 24px;
          width: 12px;
          height: 12px;
          top: 12px;
          left: 0;
          right: 0;
          margin: auto;
        }
        &.slick-next {
          &:after {
          }
        }
      }
    }
  }
`;

const ImgWrapper = styled('div')`
  border-radius: 8px;
  overflow: hidden;
`;

const sliderSettings = {
  dots: false,
  arrows: true,
  infinite: true,
  autoplay: false,
  autoplaySpeed: 5000,
  vertical: true,
  verticalSwiping: true,
  adaptiveHeight: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerPadding: '0',
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
      },
    },
  ],
};

const PlayAnyWhereWrapper = styled('div')`
  border-radius: 8px;
  background-color: ${color.darkPurple};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  margin-bottom: 18px;
  & h3 {
    font-size: ${sizing.huge};
    color: ${color.blue};
    font-weight: ${font.headingWeight};
  }
  & .gatsby-image-wrapper {
    max-width: 470px;
    width: 470px;
    margin-right: 85px;
  }
  @media (max-width: ${breakpoints.sm}) {
    & h3 {
      font-size: ${sizing.bigHeading};
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    & h3 {
      font-size: 24px;
      position: absolute;
      z-index: 10;
      left: 10px;
      max-width: 140px;
    }
    & .gatsby-image-wrapper {
      max-width: 240px;
      width: 240px;
      right: -110px;
      margin-right: 0px;
    }
    padding-top: 0;
    position: relative;
    overflow: hidden;

    & > div {
      overflow: initial;
    }
  }
  @media (max-width: ${breakpoints.xxs}) {
    & .gatsby-image-wrapper {
      right: -65px;
    }
  }
`;

const PlayItemsWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
`;

const GameSlots = ({ data, pageContext }) => {
  // Page data
  const {
    wordpressPage: currentPage,
    allWordpressWpApiMenusMenusItems: { nodes: menuNodes },
  } = data;
  const {
    featured_media,
    yoast_meta,
    title: pageTitle,
    path,
    acf: {
      game_slots: menuImages,
      title_top: titleTop,
      desc_top: descTop,
      banner_for_play_group: bannerPlayGroup,
      banner_title_for_play_group: bannerTitlePlayGroup,
      play_on_group: playGroup,
    },
  } = currentPage;

  // check if current page is casino - game
  const casinoGamesPage = path === '/casino-games/';
  const gamesSlotsPage = path === '/games-slots/';
  // console.log('Page', currentPage, pageContext);

  // Context data
  const { related: relatedIds, breadcrumbs } = pageContext;

  // Meta data
  const {
    yoast_wpseo_title: metaTitle = `${pageTitle}- ${process.env.SITE_NAME_SHORT}`,
    yoast_wpseo_metadesc: metaDesc,
  } = yoast_meta;

  // Media data
  const { alt_text } = featured_media;
  const banner = getFile(featured_media);

  // TODO : enable for dev when be completed
  const enable = true;

  const [localError, setLocalError] = useState(false);

  useEffect(() => {
    try {
      if (window.localStorage) {
        setLocalError(false);
      }
    } catch (error) {
      setLocalError(true);
    }
  });

  if (!localError) {
    return (
      <Layout>
        <SEO showSchema title={metaTitle} description={metaDesc} pathname={path} breadcrumbs={breadcrumbs} />
        {enable ? (
          <Wrapper>
            {/* Banner */}
            <FeaturedWrapper>
              <Breadcrumbs breadcrumbs={breadcrumbs} page_title={pageTitle} />
              {banner && <Img fluid={banner} alt={alt_text || pageTitle} fadeIn={false} loading="eager" />}
              <h1>
                <img className="iconImage" src={liveСasino} alt="Live Casino" />
                <span>{pageTitle}</span>
              </h1>
            </FeaturedWrapper>

            {/* Page Description */}
            {(casinoGamesPage || gamesSlotsPage) && (
              <div className="page_description">
                <h1>{titleTop}</h1>
                <div className="description_content">{descTop}</div>
              </div>
            )}
            {/* Menu */}
            <MenuWrapper>
              {relatedIds.length && (
                <MenuTabs className="tab-menu">
                  {menuNodes[0].items?.map(({ type_label, object_slug, title, path }) => (
                    <li key={type_label + object_slug}>
                      <Link activeClassName="active" to={categoryLinksInterpolate(path)}>
                        {title}
                      </Link>
                    </li>
                  ))}
                </MenuTabs>
              )}
              {/* Menu Content for Desktop */}
              {menuImages && (
                <MenuContent className="desktop-view">
                  {menuImages?.map(({ acf: { slide_url, slide_title }, altText, localFile }) => (
                    <Link to={validateLink(slide_url)} className="game">
                      <ImgWrapper>
                        <Img fluid={getFile({ localFile })} alt={altText || 'Sbobet'} fadeIn={false} loading="eager" />
                      </ImgWrapper>
                      <div className="text">
                        <span className="title">{slide_title}</span>
                      </div>
                    </Link>
                  ))}
                </MenuContent>
              )}

              {/* Menu Content for Mobile */}
              {menuImages && (
                <MenuContent className="mobile-view slider">
                  <Slider {...sliderSettings}>
                    {menuImages?.map(
                      ({ acf: { slide_url, slide_title }, altText, localFile, index }) =>
                        localFile && (
                          <SlickSlideMenuContentMobile
                            key={index}
                            slideUrl={slide_url}
                            img={getFile({ localFile })}
                            title={slide_title}
                            altText={altText || 'Sbobet'}
                          />
                        ),
                    )}
                  </Slider>
                </MenuContent>
              )}
            </MenuWrapper>

            {/* SBOSBA */}
            <SBOSBA mode="gray" />

            {/* Play Where you wants Block */}
            {casinoGamesPage && (
              <>
                <PlayAnyWhereWrapper className="play-anywhere">
                  <h3>{bannerTitlePlayGroup}</h3>
                  <ImgWrapper>
                    <Img
                      fluid={getFile(bannerPlayGroup)}
                      alt={bannerPlayGroup?.altText || 'Banner Play where you want'}
                      fadeIn={false}
                      loading="eager"
                    />
                  </ImgWrapper>
                </PlayAnyWhereWrapper>
                <PlayItemsWrapper className="play-items">
                  {playGroup?.map((item, index) => (
                    <PlayItem key={index} image={item} title={item.acf.slide_title} />
                  ))}
                </PlayItemsWrapper>
              </>
            )}
            {/* Whysbobet */}
            <WhySbobet mode="gray" />
          </Wrapper>
        ) : (
          <h1 align="center">{dictionary.comingSoon}</h1>
        )}
      </Layout>
    );
  }

  return (<CookieDisableComponent />);
};

export default GameSlots;

export const pageQuery = graphql`
  query($id: String!, $related: [Int]) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      date(formatString: "MMMM DD, YYYY")
      path
      excerpt
      content
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_template
        title_top
        desc_top
        banner_for_play_group {
          localFile {
            childImageSharp {
              fluid(maxWidth: 460) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          alt_text
        }
        banner_title_for_play_group
        play_on_group {
          acf {
            slide_url
            slide_title
          }
          localFile {
            childImageSharp {
              fluid(maxWidth: 150) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          alt_text
        }
        game_slots {
          alt_text
          title
          id
          acf {
            slide_url
            slide_title
            slide_description
          }
          localFile {
            childImageSharp {
              fluid(maxWidth: 260) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 1980) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    allWordpressPost(filter: { wordpress_id: { in: $related } }, limit: 4) {
      edges {
        node {
          slug
          title
          excerpt
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 430) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    allWordpressWpApiMenusMenusItems(filter: { slug: { eq: "games-slots" } }) {
      nodes {
        id
        name
        slug
        items {
          type_label
          title
          object_slug
          url
          path
        }
      }
    }
  }
`;
